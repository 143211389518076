import React, { useContext } from 'react';
import { NavLink, Link, useNavigate } from 'react-router-dom';

import api from '../services/api';
import AuthContext from '../contexts/auth';

import logo from '../assets/images/logo.png';
import icon from '../assets/images/icon.png';

export default function Sidebar() {

    const { signOut } = useContext(AuthContext);
    const navigate = useNavigate();
    
    function logout(){
        signOut();
        navigate('/login');
        /* history.push(location.pathname+location.search); */
    }

    return (
        <div className="sidebar-container">
            <div className="sidebar-logo">
                <img src={logo} className="sidebar-logo-img" />
            </div>
            <NavLink to="/" className="menu-sidebar dont-click" activeClassName="active">BRUK SOCIAL</NavLink>
            <NavLink to="/calendar" className="menu-sidebar dont-click" activeClassName="active">AGENDA</NavLink>
            <NavLink to="/programs" className="menu-sidebar" activeClassName="active">PROGRAMAS</NavLink>
            <NavLink to="/streaming" className="menu-sidebar dont-click" activeClassName="active">STREAMING</NavLink>
            <NavLink to="/records" className="menu-sidebar dont-click" activeClassName="active">RECORDS</NavLink>
            <NavLink to="/health" className="menu-sidebar dont-click" activeClassName="active">SAÚDE</NavLink>
            <NavLink to="/photos" className="menu-sidebar dont-click" activeClassName="active">FOTOS</NavLink>
            <NavLink to="/events" className="menu-sidebar dont-click" activeClassName="active">EVENTOS</NavLink>
            <NavLink to="/news" className="menu-sidebar dont-click" activeClassName="active">NOVIDADES</NavLink>
            <NavLink to="/courses" className="menu-sidebar dont-click" activeClassName="active">CURSOS</NavLink>
            <NavLink to="/store" className="menu-sidebar dont-click" activeClassName="active">LOJA</NavLink>
            <div className="sidebar-links">
                <Link to="/privacy-policy" className="menu-link dont-click">Política de Privacidade</Link>
            </div>
            <div className="sidebar-footer">
                <img src={icon} className="sidebar-footer-img" />
                <a onClick={logout} className="menu-footer-link">SAIR</a>
            </div>
        </div>
    );
}